import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './SignIn';
import Invitations from './Invitations';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/sign-in' element={<SignIn />}></Route>
          <Route path='/' element={<Invitations />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
