import EMAxios from './RestUtils';

const RestAPIs = {};

RestAPIs.createInvitations = async (number, desc) => {
    const { data } = await EMAxios.post('/admin/user/invitation_codes', {
        number, desc
    });

    return data;
};

RestAPIs.getInvitations = async () => {
    const { data } = await EMAxios.post('/admin/user/invitation_codes/search', {
        "filter": {
            "status": "PENDING"
        },
        "returnFields": "id code status refer{email} desc"
    });

    return data;
};

RestAPIs.consumeInvitations = async (codes) => {
    const { data } = await EMAxios.post('/admin/user/invitation_codes/status/occupation', {
        codes,
        "desc": (+new Date()).toString()
    });

    return data;
};

export default RestAPIs;