import { Button, Form, Input, message } from 'antd';
import './SignIn.css';
import EMAxios from './shared/RestUtils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function SignIn() {
    const [messageApi, contextHolder] = message.useMessage();
    const navigator = useNavigate();

    const onFinish = useCallback(async (values) => {
        try {
            let result = await EMAxios.post('/user/users/signin', values);
            let { data: { token } } = result;
            localStorage.setItem('user.token', token);

            navigator('/');
        }
        catch (err) {
            console.dir(err);
            messageApi.open({
                type: 'error',
                content: "Sign in failed",
            });
        }
    }, [messageApi, navigator]);

    return <div className='SignIn'>
        {contextHolder}
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </div>
}

export default SignIn;