import axios, { AxiosError } from 'axios';

console.log(process.env.REACT_APP_API_SERVER);
const EMAxios = axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
});

EMAxios.interceptors.request.use(function(config) {
    const token = localStorage.getItem('user.token');
    if (token !== null) {
        config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
});

EMAxios.interceptors.response.use(function (response) {
    return response;
}, function (err) {
    if (err instanceof AxiosError && err.response.status === 401) {
        console.log('redirecting...');
        window.location.href = './#sign-in';
    }
    else {
        return Promise.reject(err);
    }
});

export default EMAxios;