import { useCallback, useEffect, useState } from 'react';
import './Invitations.css';
import { Table, message, Button, Modal, Form, InputNumber, Space } from 'antd';
import { PlusOutlined, LockOutlined } from '@ant-design/icons'
import RestAPIs from './shared/RestAPIs';

const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Comments',
        dataIndex: 'desc',
        key: 'desc',
    }
];

function Invitations() {
    const [invitations, setInvitations] = useState([]);
    const [isLoadingCodes, setIsLoadingCodes] = useState(false);
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [newCodeModalOpened, setNewCodeModalOpened] = useState(false);
    const [isCreatingNewCodes, setIsCreatingNewCodes] = useState(false);
    const [consumeCodeModalOpened, setConsumeCodeModalOpened] = useState(false);
    const [isSelectedEmpty, setIsSelectedEmpty] = useState(true);
    const [isConsumingCodes, setIsConsumingCodes] = useState(false);
    const [selectionType] = useState('checkbox');
    const [form] = Form.useForm();

    const reloadInvitations = useCallback(async () => {
        try {
            setIsLoadingCodes(true);
            let newCodes = await RestAPIs.getInvitations();

            newCodes.forEach(item => {
                item['key'] = item['id'];
            });

            setInvitations(newCodes);
        }
        catch (err) {
            messageApi.open({
                type: 'error',
                content: err.toString(),
            });
        }
        finally {
            setIsLoadingCodes(false);
        }
    }, [messageApi]);

    useEffect(() => { reloadInvitations(); }, [reloadInvitations]);

    const openNewInvitationModal = useCallback(() => {
        setNewCodeModalOpened(true);
    }, []);

    const closeNewCodeModal = useCallback(() => {
        setNewCodeModalOpened(false);
    }, []);

    const onNewInvitationCodes = useCallback(async ({ number }) => {
        try {
            setIsCreatingNewCodes(true);
            console.log(`request to create ${number} invitation codes`)
            await RestAPIs.createInvitations(number, '');
            setSelectedCodes([]);

            await reloadInvitations();
            messageApi.open({
                type: 'success',
                content: `New invitation created: ${number}`,
            });
        } catch (ex) {
            messageApi.open({
                type: 'error',
                content: ex.toString(),
            });
        } finally {
            setNewCodeModalOpened(false);
        }
    }, [messageApi, reloadInvitations]);

    const openConsumeCodeModal = useCallback(() => {
        setConsumeCodeModalOpened(true);
    }, []);

    const closeConsumeCodeModal = useCallback(() => {
        setConsumeCodeModalOpened(false);
    }, []);

    const consumeCodes = useCallback(async () => {
        try {
            setIsConsumingCodes(true);
            let { success, failed } = await RestAPIs.consumeInvitations(selectedCodes);
            let message = `Claim success ${success.length}`;
            if (failed.length > 0) {
                message += `, failed ${failed.length}`;
            }

            await reloadInvitations();
            setConsumeCodeModalOpened(false);
            messageApi.success(message);
        }
        catch (ex) {
            messageApi.error(`Claim code failed: ${ex}`);
        } finally {
            setIsConsumingCodes(false);
        }
    }, [selectedCodes, messageApi, reloadInvitations]);

    const copyCodes = useCallback(async () => {
        await navigator.clipboard.writeText(selectedCodes.join('\r\n'));
        messageApi.success('Code copied');
    }, [selectedCodes, messageApi]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            let newSelected = selectedRows.map(r => r.code);
            setSelectedCodes(newSelected);
            setIsSelectedEmpty(newSelected.length === 0);
        },
    };

    return <div className='container'>
        {contextHolder}
        <h1>Invitation Codes</h1>

        <div>
            <Table dataSource={invitations} columns={columns} pagination={false} size='small' loading={isLoadingCodes}
                rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                }}
                title={() => {
                    return <div className='toolbar'>
                        <div>Total: {invitations.length}</div>
                        <div>
                            <Button type="primary" shape='circle' className='tool-item' icon={<LockOutlined />} onClick={openConsumeCodeModal} disabled={isSelectedEmpty}></Button>
                            <Button type="primary" shape='circle' className='tool-item' icon={<PlusOutlined />} onClick={openNewInvitationModal}></Button>
                        </div>
                    </div>
                }}></Table>
        </div>

        <Modal
            title="Create new invitation codes"
            open={newCodeModalOpened}
            confirmLoading={isCreatingNewCodes}
            onCancel={closeNewCodeModal}
            footer={[
                <Button form="createInvitationCodesForm" key="submit" htmlType="submit">
                    Submit
                </Button>
            ]}
        >
            <div>
                <p>Input how many codes you want to generate, and write some description for memo.</p>
                <Form name='createInvitationCodesForm' form={form} layout="vertical" autoComplete="off" onFinish={onNewInvitationCodes}>
                    <Form.Item name="number" label="Code count (max: 50)">
                        <InputNumber min={1} max={50} />
                    </Form.Item>
                    {/* <Form.Item name="desc" label="Code memo">
                        <TextArea style={{height: 100}} placeholder='Code memo could help us to track the usage, you can write the purpose, target user or group etc.' />
                    </Form.Item> */}
                </Form>
            </div>
        </Modal>

        <Modal
            title="Claim codes"
            open={consumeCodeModalOpened}
            onCancel={closeConsumeCodeModal}
            onFinish={consumeCodes}
            confirmLoading={isCreatingNewCodes}
            footer={() => [
                <Button key="cancel" onClick={closeConsumeCodeModal}>Cancel</Button>,
                <Button key="copyCodes" onClick={copyCodes}>Copy codes</Button>,
                <Button key="consumeCodes" type="primary" onClick={consumeCodes} loading={isConsumingCodes}>Claim</Button>
            ]}
        >
            <div>
                <p>You are consuming <span className='highlight-text'>{selectedCodes.length}</span> codes. Confirm to copy code in clipboard and paste to the cloud doc.</p>

                <div className='well' style={{ marginTop: 16 }}>
                    <Space wrap>
                        {selectedCodes.map(code => <span key={code}>{code}</span>)}
                    </Space>
                </div>
            </div>
        </Modal>
    </div>
}

export default Invitations;